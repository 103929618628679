import { IonThumbnail } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Attachment } from '../../lib/ApiModel';
import { CancellablePromise } from '../../lib/helper/PromiseHelper';
import ProjectAssetSiteData from '../../lib/ProjectAssetData';

export function AttachmentThumbnail({
    attachment,
    siteData,
    slot,
}: {
    slot?: string;
    attachment: Attachment;
    siteData: ProjectAssetSiteData;
}) {
    const [dataurl, setdataurl] = useState<string | undefined>();
    useEffect(() => {
        const prom = new CancellablePromise(() => siteData.getAttachmentThumbnailDataUrl(attachment, 100, 100));
        prom.result.then((url) => {
            setdataurl(url);
        });

        return () => prom.cancel();
    }, [attachment, siteData]);

    return (
        <IonThumbnail title={dataurl} slot={slot}>
            <img src={dataurl} />
        </IonThumbnail>
    );
}

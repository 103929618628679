import { IonCol, IonContent, IonGrid, IonPage, IonProgressBar, IonRouterOutlet, IonRow } from '@ionic/react';
import { Route, useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useProjectAssetData } from '../../lib/useProjectAssetData';
import CollectUsingStrategy from './CollectUsingStrategy';
import SiteHome from './SiteHome';
import { Site } from '../../lib/sites';
import SitePhotoCollection from './SitePhotoCollection';
import SiteEdit from './SiteEdit';
import SitePhotoItemCollection from './SitePhotoItemPage';

const SiteCollectionRouter: React.FC = () => {
    const { projectid, assetid, siteid } = useParams<{ projectid: string; assetid: string; siteid: string }>();
    const currentRoute = useRouteMatch();
    const siteData = useProjectAssetData(projectid, assetid, siteid);

    return (
        <IonPage>
            {siteData && (
                <IonRouterOutlet>
                    <Route exact path={`${currentRoute.url}`}>
                        <SiteHome siteData={siteData} />
                    </Route>
                    <Route exact path={`${currentRoute.url}/siteedit`}>
                        <SiteEdit siteData={siteData} />
                    </Route>
                    <Route exact path={`${currentRoute.url}/sitephotos`}>
                        <SitePhotoCollection siteData={siteData} />
                    </Route>
                    <Route exact path={`${currentRoute.url}/sitephotos/:filename`}>
                        <SitePhotoItemCollection siteData={siteData} />
                    </Route>
                    <Route exact path={`${currentRoute.url}/strategy/:strategyid`}>
                        <CollectUsingStrategy siteData={siteData} />
                    </Route>
                </IonRouterOutlet>
            )}
            {!siteData && (
                <IonContent>
                    <IonGrid>
                        <IonRow className="ion-align-items-center ion-justify-content-center">
                            <IonCol>
                                <div>Loading Site Data...</div>
                                <IonProgressBar type="indeterminate" />
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            )}
        </IonPage>
    );
};

export default SiteCollectionRouter;

export type SiteChildPageProps = {
    site?: Site;
};

import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { resizeImageDataUrl } from './AttachmentHelper';

const PreferredShortestEdgeSide = 1080;

export async function takePhotoReturningDataUrl(): Promise<string | undefined> {
    const img = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        direction: CameraDirection.Rear,
        webUseInput: true,
        width: 2048,
        quality: 95,
    });

    if (!img.dataUrl) return undefined;

    return await resizeImageDataUrl(img.dataUrl, PreferredShortestEdgeSide);
}

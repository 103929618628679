import { IonContent, IonPage, IonList, IonItem, IonLabel, IonText, IonListHeader } from '@ionic/react';
import { useProjects } from '../lib/useProjects';
import { useHistory } from 'react-router-dom';
import AfnHeader from '../components/AfnHeader';

const ProjectList: React.FC = () => {
    const projects = useProjects();
    const history = useHistory();

    return (
        <IonPage>
            <AfnHeader showBackButton={false}>Projects</AfnHeader>
            <IonContent>
                {projects.length == 0 && (
                    <div className="ion-padding">
                        <h1>Getting Started</h1>
                        <p>
                            Welcome to the EVA Field Agent application. This application can be used to collect images
                            and measurements for your AfN EVA projects.
                        </p>
                        <p>
                            Use the &quot;Sync&quot; tab below to download and upload data to and from your AfN projects
                        </p>
                        <p style={{ marginTop: '2rem', fontSize: '0.9rem' }}>
                            <i>
                                <b>NOTE:</b> To collect data, the project must be registered in the AfN EVA portal, and
                                the data collection year needs to have been created.
                            </i>
                        </p>
                    </div>
                )}
                {projects.length > 0 && (
                    <IonList>
                        <IonListHeader>My Projects</IonListHeader>
                        {projects.map((project, index) => (
                            <IonItem
                                key={index}
                                detail={true}
                                button={true}
                                onClick={() => history.push({ pathname: '/projects/' + project.id })}
                            >
                                <IonLabel className="ion-text-wrap">
                                    <IonText color="primary">
                                        <h1>{project.name}</h1>
                                    </IonText>
                                    <IonText>
                                        <p>{project.locations.join(', ')}</p>
                                    </IonText>
                                </IonLabel>
                            </IonItem>
                        ))}
                    </IonList>
                )}
            </IonContent>
        </IonPage>
    );
};

export default ProjectList;

import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonSlide,
    IonSlides,
    IonTitle,
    IonToolbar,
    useIonModal,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import AfnHeader from '../../components/AfnHeader';
import Markdown from '../../components/Markdown';
import { Attachment, MeasurementAttachmentDetail } from '../../lib/ApiModel';
import { sanitizeForFilename } from '../../lib/helper/AttachmentHelper';
import { takePhotoReturningDataUrl } from '../../lib/helper/CameraHelper';
import { CancellablePromise } from '../../lib/helper/PromiseHelper';
import { formatBytes, formatDate } from '../../lib/helper/StringHelper';
import ProjectAssetSiteData from '../../lib/ProjectAssetData';
import { download } from 'ionicons/icons';

import './SitePhotoItemPage.css';

function SitePhotoInfoModal({
    attachment,
    sitePhoto,
    onClose,
}: {
    attachment: Attachment;
    sitePhoto: MeasurementAttachmentDetail;
    onClose: () => unknown;
}) {
    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>{sitePhoto.name}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onClose()}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{ padding: '1rem' }} className="sitePhotoInfoModal">
                    <h4>Photo Information</h4>
                    <dl>
                        <dt>Size</dt>
                        <dd>{formatBytes(attachment.sizeBytes)}</dd>

                        <dt>Format</dt>
                        <dd>{attachment.contentType}</dd>

                        <dt>Attached Date</dt>
                        <dd>{attachment.uploadedDate ? formatDate(attachment.uploadedDate) : 'Not Synced'}</dd>
                    </dl>
                    <h4>Acquisition Instructions:</h4>
                    <Markdown source={sitePhoto.description} />
                </div>
            </IonContent>
        </>
    );
}


function SitePhotoItemDetail({
    sitePhoto,
    siteData,
}: {
    sitePhoto: MeasurementAttachmentDetail;
    siteData: ProjectAssetSiteData;
}) {
    const existingPhoto = siteData.attachmentForPhoto(sitePhoto.fileName);

    const [dataurl, setdataurl] = useState<string | undefined>();

    const closeModal = () => {
        closeDescription();
    };

    const [showDescription, closeDescription] = useIonModal(SitePhotoInfoModal, {
        attachment: existingPhoto,
        sitePhoto: sitePhoto,
        siteName: siteData.site.name,
        onClose: closeModal,
    });

    useEffect(() => {
        if (!existingPhoto) {
            setdataurl(undefined);
            return;
        } else {
            const prom = new CancellablePromise<string | undefined>(
                async () => await siteData.getAttachmentAsDataUrl(existingPhoto)
            );
            prom.result.then((url) => {
                setdataurl(url);
            });
            prom.result.catch((e) => console.error(e));

            return () => prom.cancel();
        }
    }, [existingPhoto, siteData]);

    const capture = async () => {
        const photo = await takePhotoReturningDataUrl();
        if (photo) {
            await siteData.createSiteAttachment(photo, sitePhoto.fileName, 'SiteDataPhoto');
        }
    };

    const remove = async (existingAttachmentId: string) => {
        await siteData.deleteSiteAttachment(existingAttachmentId);
    };

    return (
        <div slot="fixed" style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            {existingPhoto && (
                <>
                    <IonSlides
                        style={{ backgroundColor: '#303030', flex: '1 0 50%' }}
                        options={{ zoom: { maxRatio: 100 } }}
                    >
                        {dataurl && (
                            <IonSlide>
                                <img src={dataurl} />
                            </IonSlide>
                        )}
                    </IonSlides>

                    <div style={{ padding: '0.5rem 1rem', flex: '0 0 0%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <IonButton color="danger" onClick={() => remove(existingPhoto.id)}>
                                Remove
                            </IonButton>
                            <IonButton color="secondary" onClick={() => showDescription()}>
                                Info
                            </IonButton>
                            <IonButton
                                color="tertiary"
                                download={sanitizeForFilename(
                                    `${siteData.project.registeredProjectId ?? ''}_${siteData.assessmentUnit.name}_${
                                        siteData.site.name
                                    }_${existingPhoto.name}`
                                )}
                                href={dataurl}
                            >
                                <IonIcon slot="icon-only" icon={download} />
                            </IonButton>
                        </div>
                    </div>
                </>
            )}

            {!existingPhoto && (
                <>
                    <div style={{ padding: '1rem', overflow: 'hidden', flex: '1 0 50%' }}>
                        <Markdown source={sitePhoto.description} />
                    </div>
                    <div style={{ padding: '0.5rem 1rem', flex: '0 0 0%' }}>
                        <div style={{ textAlign: 'center' }}>
                            <IonButton onClick={capture}>Capture</IonButton>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}



export default function SitePhotoItemPage({ siteData }: { siteData: ProjectAssetSiteData })  {
    const sitePhotos = siteData.sitePhotos;
    const { filename } = useParams<{ filename: string }>();
    const sitePhotoItem = sitePhotos.find((sp) => sp.fileName == filename);
    const history = useHistory();
    if (!sitePhotoItem) {
        history.goBack();
        return null;
    }

    return (
        <IonPage>
            <AfnHeader showBackButton={true}>{sitePhotoItem.name}</AfnHeader>
            <IonContent>
                <SitePhotoItemDetail key={sitePhotoItem.fileName} siteData={siteData} sitePhoto={sitePhotoItem} />
            </IonContent>
        </IonPage>
    );
};

import { IonContent, IonItem, IonLabel, IonPage } from '@ionic/react';
import { useHistory, useRouteMatch } from 'react-router';

import AfnHeader from '../../components/AfnHeader';
import { MeasurementAttachmentDetail } from '../../lib/ApiModel';

import ProjectAssetSiteData from '../../lib/ProjectAssetData';
import { AttachmentThumbnail } from './AttachmentThumbnail';

type SitePhotoCollectionProps = {
    siteData: ProjectAssetSiteData;
};

function SitePhotoItem({
    sitePhoto,
    siteData,
}: {
    sitePhoto: MeasurementAttachmentDetail;
    siteData: ProjectAssetSiteData;
}) {
    const existingPhoto = siteData.attachmentForPhoto(sitePhoto.fileName);
    const history = useHistory();
    const currentRoute = useRouteMatch();

    return (
        <IonItem onClick={() => history.push(`${currentRoute.url}/${sitePhoto.fileName}`)}>
            {existingPhoto && (
                <>
                    <AttachmentThumbnail attachment={existingPhoto} siteData={siteData} slot="end" />
                </>
            )}
            <IonLabel className="ion-text-wrap">
                <h2>
                    {sitePhoto.name} {sitePhoto.mandatory && <small style={{ marginLeft: '0.5rem' }}>Required</small>}
                </h2>
            </IonLabel>
        </IonItem>
    );
}

const SitePhotoCollection: React.FC<SitePhotoCollectionProps> = ({ siteData }) => {
    const sitePhotos = siteData.sitePhotos;

    return (
        <IonPage>
            <AfnHeader showBackButton={true}>Site Photos</AfnHeader>
            <IonContent>
                {sitePhotos.map((sp) => (
                    <SitePhotoItem key={sp.fileName} siteData={siteData} sitePhoto={sp} />
                ))}
            </IonContent>
        </IonPage>
    );
};

export default SitePhotoCollection;

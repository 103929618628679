import { EventEmitter } from '../helper/EventHelper';
import storage from '../Storage';
import { MeasurementUpdate } from '../SyncHelper';

export default class MeasurementUpdatesStore {
    onChange = new EventEmitter<MeasurementUpdatesStore>();
    updates: MeasurementUpdate[] = [];
    projectId = '';

    async loadForProject(projectId: string): Promise<MeasurementUpdate[]> {
        this.projectId = projectId;
        this.updates = await storage.getMeasurementUpdates(projectId);
        this.onChange.fire(this);
        return this.updates;
    }

    setUpdates(newUpdates: MeasurementUpdate[]) {
        this.updates = newUpdates;
        this.onChange.fire(this);
        this.save();
    }

    addUpdate(update: MeasurementUpdate) {
        // remove any existing updates for the same value
        const existingIdx = this.updates.findIndex(
            (u) =>
                u.projectId == update.projectId &&
                u.assetId == update.assetId &&
                u.indicatorId == update.indicatorId &&
                u.assessmentUnitId == update.assessmentUnitId &&
                u.siteId == update.siteId
        );
        if (existingIdx >= 0) {
            this.updates.splice(existingIdx, 1);
        }

        // add our new update to the end
        this.updates.push(update);
        this.onChange.fire(this);
        this.save();
    }

    save() {
        return storage.setMeasurementUpdates(this.projectId, this.updates);
    }
}

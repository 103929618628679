import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonProgressBar, IonRow, IonIcon } from '@ionic/react';
import { syncSharp, refreshSharp } from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import AfnHeader from '../components/AfnHeader';
import { loginWithRedirect, logoutWithRedirect } from '../lib/auth/auth';
import { resetDataFromAPI, syncDataWithAPI } from '../lib/SyncHelper';
import { useUser } from '../lib/useUser';

const SyncPage: React.FC = () => {
    const user = useUser();
    const history = useHistory();

    const [isSync, setIsSync] = useState<boolean>(false);
    const [progressStatus, setProgressStatus] = useState<{ progress: number; status: string }>({
        progress: 0,
        status: '',
    });

    const doReset = () => {
        setIsSync(true);
        setProgressStatus({ progress: 0, status: '' });
        resetDataFromAPI((status, progress) => {
            setProgressStatus({ progress: progress, status: status });
        });
    };

    const doSync = () => {
        setIsSync(true);
        setProgressStatus({ progress: 0, status: '' });
        syncDataWithAPI((status, progress) => {
            setProgressStatus({ progress: progress, status: status });
        });
    };

    const doCloseSync = () => {
        setIsSync(false);
        history.push({ pathname: '/projects' });
    };

    return (
        <IonPage>
            <AfnHeader showBackButton={false}>Sync Data</AfnHeader>
            <IonContent>
                <IonGrid>
                    {!user && (
                        <>
                            <IonRow>
                                <IonCol>To synchronise projects you will need to login</IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton onClick={() => loginWithRedirect()}>Login</IonButton>
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                    {user && (
                        <>
                            {isSync ? (
                                <>
                                    <IonRow>
                                        <IonCol>
                                            <h1>Syncing</h1>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>{progressStatus.status}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonProgressBar value={progressStatus.progress / 100} type="determinate" />
                                        </IonCol>
                                    </IonRow>
                                    {progressStatus.progress == 100 && (
                                        <IonRow>
                                            <IonCol>
                                                <IonButton onClick={() => doCloseSync()}>OK</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    )}
                                </>
                            ) : (
                                <>
                                    <IonRow>
                                        <IonCol>Logged in as {user.username}</IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton onClick={() => logoutWithRedirect()}>Logout</IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow style={{ marginTop: '2rem' }}>
                                        <IonCol>
                                            This will upload any new measurements and photos into the projects, and will
                                            then download the latest data from those projects.
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol>
                                            <IonButton onClick={() => doSync()} color="secondary">
                                                <IonIcon icon={syncSharp}></IonIcon>&nbsp;Sync Now
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                    <div style={{ border: '1px solid var(--ion-color-danger)', marginTop: '3rem' }}>
                                        <IonRow>
                                            <IonCol>
                                                This will <b>abandon and delete</b> all local measurements and photos
                                                without syncing and download a fresh set of projects.
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonButton color="danger" onClick={() => doReset()}>
                                                    <IonIcon icon={refreshSharp}></IonIcon>&nbsp;Reset Data
                                                </IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};

export default SyncPage;
